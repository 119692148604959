import { Col } from '@astrn/common/components/grid';
import { ADAPTIVE } from '@astrn/common/tokens/screen';
import { HeadingLevel2 } from '@astrn/common/tokens/typography';
import { getTextStyles } from '@astrn/common/utils/styles';
import styled, { css } from 'styled-components';

export const LeftColStyled = styled.div<{ isEmptyBackground?: boolean, isActive?: boolean }>`
  text-align: center;
  color: var(--Colors-Text-primary, #FFF);
  ${getTextStyles('Montserrat', 700, 20, 30)}
  width: 50%;

  ${({ isEmptyBackground }) => !isEmptyBackground && css`
    display: flex;
    height: 58px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    background: linear-gradient(270deg, #141414 50%, rgba(20, 20, 20, 0.00) 97.29%);
  `}

  ${({ isActive }) => isActive && css`
    position: relative;
    color: var(--Colors-Text-tertiary, #BFBFBF);

  `};
`;

export const RightColStyled = styled.div<{ isEmptyBackground?: boolean, isActive?: boolean }>`
  text-align: center;
  color: var(--Colors-Text-primary, #FFF);
  ${getTextStyles('Montserrat', 700, 30, 38)}
  width: 50%;


  ${({ isEmptyBackground }) => !isEmptyBackground && css`
    display: flex;
    height: 58px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    background: linear-gradient(90deg, #1F1F1F 59.62%, rgba(31, 31, 31, 0.00) 97.28%);
  `}

  ${({ isActive }) => isActive && css`
    position: relative;
    color: var(--Colors-Text-tertiary, #BFBFBF);
  `};
`;

export const ActiveTopLineStyled = styled.div<{ isRight?: boolean }>`
  height: 1px;
  width: 100%;
  top: 0;
  position: absolute;
  ${({ isRight }) => isRight
    ? 'background: linear-gradient(90deg, #FFB22E 50%, #FFB22E00 100%)'
    : 'background: linear-gradient(270deg, #FFB22E 50%, #FFB22E00 100%)'}
`;

export const ActiveBottomLineStyled = styled.div<{ isRight?: boolean }>`
  height: 1px;
  width: 100%;
  bottom: 0;
  position: absolute;
  ${({ isRight }) => isRight
    ? 'background: linear-gradient(90deg, #FFB22E 50%, #FFB22E00 100%)'
    : 'background: linear-gradient(270deg, #FFB22E 50%, #FFB22E00 100%)'}
`;

export const TitleStyled = styled.div`
  color: #BFBFBF;
  text-align: center;

  ${getTextStyles('Montserrat', 700, 20, 30)}

  margin-bottom: 16px;
`;

export const ColStyled = styled(Col)`
  display: flex;
  gap: 3px;
  margin-bottom: 1px;
`;

export const SectionTitleStyled = styled(HeadingLevel2)`
  color: #FFF;
  text-align: center;
  ${getTextStyles('Montserrat', 700, 36, 44)}
  letter-spacing: -0.72px;
`;

export const SectionSubTitleStyled = styled.div`
  color: #F0F0F0;
  text-align: center;
  ${getTextStyles('Montserrat', 400, 20, 30)}
  margin: 0 auto 60px auto;

  width: 100%;

  ${ADAPTIVE.minWidth.tablet} {
    width: 70%;
  }

  ${ADAPTIVE.minWidth.desktop} {
    width: 35%;
  }

`;