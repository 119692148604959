import { Col, Container, Row } from '@astrn/common/components/grid';
import { ActiveBottomLineStyled, ActiveTopLineStyled, ColStyled, LeftColStyled, RightColStyled, SectionSubTitleStyled, SectionTitleStyled, TitleStyled } from './styled';
import { useParams } from 'react-router-dom';
import data from '@astrn/data/data.json';
import { find } from 'lodash';
import { useTranslation } from 'react-i18next';

export function PowerCompsumption() {
  const { id } = useParams();
  const { t } = useTranslation();

  const resource = find(data, ['id', id]);

  return (
    <Container lgPt='80px' lgPb='80px' mdPt='80px' mdPb='80px' pt='80px' pb='80px'>
      <Row>
        <Col>
          <SectionTitleStyled>{t('power-title')}</SectionTitleStyled>
          <SectionSubTitleStyled>{t('power-subtitle')}</SectionSubTitleStyled>
        </Col>
      </Row>
      <Row>
        <Col lg={3} />
        <ColStyled lg={6}>
          <LeftColStyled isEmptyBackground>
            <TitleStyled>{t('power-table-title1')}</TitleStyled>
          </LeftColStyled>
          <RightColStyled isEmptyBackground>
            <TitleStyled>{t('power-table-title2')}</TitleStyled>
          </RightColStyled>
        </ColStyled>
        <Col lg={3} />
      </Row>
      {resource?.tableList?.map((item: any) => (
        <Row>
          <Col lg={3} />
          <ColStyled lg={6}>
            <LeftColStyled isActive={item?.isActive}>
              {item?.isActive && <ActiveTopLineStyled />}
                {item.hashrate} {item?.isActive && t('power-table-stock')}
              {item?.isActive && <ActiveBottomLineStyled />}
            </LeftColStyled>
            <RightColStyled isActive={item?.isActive}>
              {item?.isActive && <ActiveTopLineStyled isRight />}
                {item.kWt}
              {item?.isActive && <ActiveBottomLineStyled isRight />}
            </RightColStyled>
          </ColStyled>
          <Col lg={3} />
        </Row>
      ))}
    </Container>
  );
}