import React from 'react';
import { ColStyled, ContainerStyled, DescriptionStyled, GradientLayerStyled, HeadingStyled, ParallaxBannerStyled, PerformanceSubTitleStyled, PerformanceTitleStyled, TextStyled } from './styled';
import { Col, Row } from '../../../../common/components/grid';
import ReloadIcon from './icons/reload.svg';
import ElectricIcon from './icons/electric.svg';
import FanIcon from './icons/fan.svg';
import IntegrationIcon from './icons/integration.svg';
import SuccessIcon from './icons/success.svg';
import SettingsIcon from './icons/settings.svg';
import { useTranslation } from 'react-i18next';

export function Performance() {

  const { t } = useTranslation();

  return (
    <ContainerStyled lgPt='80px' mdPt='80px' pt='40px'>
      <Row lgMb='80px' mdMb='80px' mb='40px'>
        <Col>
          <PerformanceTitleStyled dangerouslySetInnerHTML={{__html: t('performance-title') }}></PerformanceTitleStyled>
          <PerformanceSubTitleStyled>{t('performance-description')}</PerformanceSubTitleStyled>
        </Col>
      </Row>
      <Row>
        <ColStyled lg={4} md={4} sm={4}>
          <img src={ReloadIcon} alt="" />
          <HeadingStyled>{t('performance-block1-title')}</HeadingStyled>
          <DescriptionStyled>{t('performance-block1-description')}</DescriptionStyled>
        </ColStyled>
        <ColStyled lg={4} md={4} sm={4}>
          <img src={ElectricIcon} alt="" />
          <HeadingStyled>{t('performance-block2-title')}</HeadingStyled>
          <DescriptionStyled>{t('performance-block2-description')}</DescriptionStyled>
        </ColStyled>
        <ColStyled lg={4} md={4} sm={4}>
          <img src={FanIcon} alt="" />
          <HeadingStyled>{t('performance-block3-title')}</HeadingStyled>
          <DescriptionStyled>{t('performance-block3-description')}</DescriptionStyled>
        </ColStyled>
        <ColStyled lg={4} md={4} sm={4}>
          <img src={IntegrationIcon} alt="" />
          <HeadingStyled>{t('performance-block4-title')}</HeadingStyled>
          <DescriptionStyled>{t('performance-block4-description')}</DescriptionStyled>
        </ColStyled>
        <ColStyled lg={4} md={4} sm={4}>
          <img src={SuccessIcon} alt="" />
          <HeadingStyled>{t('performance-block5-title')}</HeadingStyled>
          <DescriptionStyled>{t('performance-block5-description')}</DescriptionStyled>
        </ColStyled>
        <ColStyled lg={4} md={4} sm={4}>
          <img src={SettingsIcon} alt="" />
          <HeadingStyled>{t('performance-block6-title')}</HeadingStyled>
          <DescriptionStyled>{t('performance-block6-description')}</DescriptionStyled>
        </ColStyled>
      </Row>
      {/* <Row rowGap='75px'>
        <ColStyled lg={6} md={4} sm={4} alignItems='start'>
          <TextStyled>Different acceleration modes</TextStyled>
        </ColStyled>
        <ColStyled lg={6} md={4} sm={4} alignItems='end'>
          <TextStyled>Quick switch between performance modes</TextStyled>
        </ColStyled>
        <ColStyled lg={6} md={4} sm={4} alignItems='start'>
          <TextStyled>Voltage settings for stable operation</TextStyled>
        </ColStyled>
        <ColStyled lg={6} md={4} sm={4} alignItems='end'>
          <TextStyled>Different acceleration modes</TextStyled>
        </ColStyled>
        <ColStyled lg={6} md={4} sm={4} alignItems='start'>
          <TextStyled>Quick switch between performance modes</TextStyled>
        </ColStyled>
        <ColStyled lg={6} md={4} sm={4} alignItems='end'>
          <TextStyled>Voltage settings for stable operation</TextStyled>
        </ColStyled>
      </Row> */}
      {/* <ParallaxBannerStyled
        layers={[layer4, layer2]}
      /> */}
    </ContainerStyled>
  );
}
