import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "home-hero-title1": "Efficient",
      "home-hero-title2": "over<wbr />CLOCKING",
      "home-hero-title3": "your miner",
      "home-hero-title4": "equipment",
      "home-hero-description": "Increase hashrate performance and your profits, while conserving energy and decreasing power consumption.",
      "home-hero-button": "SUPPORTED MINERS",
      "menu-item-home": "Home",
      "menu-item-resources": "Equipment",
      "menu-item-faq": "FAQ",
      "menu-item-contact": "Contacts",
      "menu-item-billing": "Billing",
      "income-upto-text": "Up to",
      "income-value-text": "50%",
      "income-hashrate-increase-text": "hashrate increase",
      "income-description-text": "Increase performance of your equipment without hardware modifications.",
      "income-overclocking-text": "OVERCLOCKING",
      "income-overclocking-description-text": "<p>Choose overclocking modes: factory power supply, custom or liquid cooling.</p><p>Multiple overclocking values available for different devices. 30% average performance increase.</p>",
      "efficiency-upto-text": "Up to",
      "efficiency-value-text": "15%",
      "efficiency-hashrate-increase-text": "energy saving",
      "efficiency-description-text": "Save energy consumption and money while retaining the same performance",
      "efficiency-overclocking-text": "Efficiency",
      "efficiency-overclocking-description-text": "<p>Optimize power consumption with manual voltage correction tool.</p><p>Auto mode selects optimal voltage to save energy.</p><p>Environment tool analyses surrounding conditions in real time and changes voltage to maximise performance.</p>",
      "achive-text": "<span>SINCE 2018</span> WE'VE BEEN HELPING CUSTOMERS EARN MORE, PROVIDING TECHNICAL SUPPORT AND RELEASING UPDATES — EACH TIME MAKING THE PRODUCT EVEN BETTER",
      "supported-hardware-title": "SUPPORTED HARDWARE UPGRADE",
      "supported-hardware-description": "Pricing that scales with your business instantly",
      "supported-hardware-button": "ALL AVAILABLE MINERS",
      "banner-title": "No hidden fees",
      "banner-subtitle1": "NO COMMISSIONS, PAY MONTHLY",
      "banner-subtitle2": "OR BUY LIFETIME LICENSE",
      "achive-th-value": "8.4 mln+",
      "achive-th-title": "Terahash Increased",
      "achive-devices-value": "280 000+",
      "achive-devices-title": "Devices Overclocked",
      "achive-clients-value": "7 600",
      "achive-clients-title": "Unique Clients",
      "admin-banner-title": "Administrator Panel App",
      "admin-banner-description": "<p>Retain your original firmware panel.</p><p>Additional control and data for your boards and chips. Improved log file data. Easy to use interface.</p><p>Always stay connected to your miner equipment wherever you are.</p>",
      "admin-banner-button": "OPEN ADMIN APP DEMO",
      "performance-title": "Key Features",
      "performance-description": "Up to 15% energy efficiency from factory settings",
      "performance-block1-title": "Easy Updates",
      "performance-block1-description": "Manual update tool available. Choose with a click of a button when to update your miner.",
      "performance-block2-title": "Overheat Protection",
      "performance-block2-description": "Our software automatically reduces power to prevent overheating and continue mining.",
      "performance-block3-title": "Fans Disabling",
      "performance-block3-description": "Maximum performance modes available and fans shutdown mode for liquid cooling.",
      "performance-block4-title": "Monitoring Systems",
      "performance-block4-description": "Always stay connected to your equipment, wherever you are. BTC tools support.",
      "performance-block5-title": "Watchdog",
      "performance-block5-description": "Software can automatically reboot the device in case of a critical error.",
      "performance-block6-title": "Factory Reset",
      "performance-block6-description": "Restore your factory settings easily:  simply remove the flash drive",
      "device-management-title": "Batch Device Management",
      "device-management-description1": "Change any firmware setting you require and force upload updates to multiple devices.",
      "device-management-description2": "BTC tools integration available, can be used to monitor multiple devices.",
      "device-management-description3": "Download your data via HTTP to integrate into existing device management system.",
      "device-management-button": "CHOOSE YOUR MINER",
      "connected-title": "<span>24 HOUR SUPPORT</span> customer service available to all our clients",
      "email": "Email",
      "message": "Message",
      "telegram": "TELEGRAM",
      "your-email": "Your email...",
      "your-message": "Your message...",
      "sent": "SEND",
      "admin-panel-link-title": "TRY DEMO APP",
      "admin-panel-description": "The firmware interface is simple and easy to use. Always stay connected to your miner equipment wherever you are. You can see how the administration panel works.",
      "copyright": "© 2023 Asteron. All rights reserved.",
      "chips": "chips",
      "see-details": "See details",
      "faq-title": "Have any additional questions?",
      "faq-subtitle": "If you still have any questions, please ask us and we will answer them for you",
      "faq-button-text": "Add question",
      "question-section-title": "Frequently Asked Questions",
      "question-section-subtitle": "We have tried to collect answers to the most frequent questions about our overclocking software.",
      "download-section-title": "Download",
      "download-section-subtitle": "Use the image as reference to download correct firmware",
      "download-section-instruction-text": "Manual",
      "download-section-download-text": "Download",
      "download-section-firmware-text": "Firmware",
      "energysave-section-text1": "Up to",
      "energysave-section-text2": "Hashrate Increase",
      "energysave-section-text3": "Increase performance of your equipment without any modifications.",
      "energysave-section-text4": "Up to",
      "energysave-section-text5": "Energy Saving",
      "energysave-section-text6": "Save energy consumption and money while retaining the same performance",
      "power-title": "POWER CONSUMPTION",
      "power-subtitle": "Please note that data may vary depending on specific environment or set up properties.",
      "power-table-title1": "HASHRATE",
      "power-table-title2": "kWt",
      "power-table-stock": "(stock)",
      "resources-title": "SUPPORTED MINERS",
      "resources-subtitle": "<p>Our overclocking software is available for most popular miners.</p><p>Select the one that you use for more detailed information.</p>",
      "faq-home-button": "FULL FAQ",
      "resource-payment-accept-method": "Accepted payment methods",
      "resource-payment-method-1-title": "Monthly payment",
      "resource-payment-method-2-title": "Annual Payment",
      "resource-payment-method-3-title": "One-off payment",
      "resource-payment-period": "Payment period",
      "resource-price-section-title": "Select the payment period to see the price",
      "resource-payment-method-1": "Lifetime",
      "resource-payment-method-2": "Annually",
      "resource-payment-method-3": "Monthly"
    }
  },
  ru: {
    translation: {
      "home-hero-title1": "Больше",
      "home-hero-title2": " терахеш",
      "home-hero-title3": "для ваших майнеров",
      "home-hero-title4": "навсегда",
      "home-hero-description": "Мы безопасно и эффективно ускоряем ваше оборудование и экономим электроэнергию.",
      "home-hero-button": "ВЫБРАТЬ МАЙНЕР",
      "menu-item-home": "Главная",
      "menu-item-resources": "Устройства",
      "menu-item-faq": "Вопросы",
      "menu-item-contact": "Контакты",
      "menu-item-billing": "Биллинг",
      "income-upto-text": "До",
      "income-value-text": "50%",
      "income-hashrate-increase-text": "увеличение хешрейта",
      "income-description-text": "Увеличьте производительность вашего майнера без модификаций оборудования.",
      "income-overclocking-text": "Разгон",
      "income-overclocking-description-text": "<p>Разные режимы разгона — для заводских блоков питания, для кастомизированных, для жидкостного охлаждения.</p><p>Для разных устройств предусмотрены различные значения для разгона, в среднем, можно добиться до +30% от начальной производительности.</p>",
      "efficiency-upto-text": "До",
      "efficiency-value-text": "30%",
      "efficiency-hashrate-increase-text": "экономия электроэнергии",
      "efficiency-description-text": "Снижайте потребление электроэнергии, без изменения производительности майнера.",
      "efficiency-overclocking-text": "Эффективность",
      "efficiency-overclocking-description-text": "<p>Ручная коррекция напряжения, для оптимизации потребления электроэнергии.</p><p>Автоматический выбор оптимального значения напряжения для потребления наименьшего количества электроэнергии.</p><p>Модуль, реагирующий на изменение окружающих условий и в реальном времени изменяющий значение напряжения.</p>",
      "achive-text": "<span>С 2018 ГОДА</span> МЫ ПОМОГАЕМ НАШИМ КЛИЕНТАМ ЗАРАБАТЫВАТЬ БОЛЬШЕ, ПОДДЕРЖИВАЕМ, ПРИСЛУШИВАЕМСЯ, ВЫПУСКАЕМ ОБНОВЛЕНИЯ — КАЖДЫЙ РАЗ СОВЕРШЕНСТВУЯ НАШ ПРОДУКТ",
      "supported-hardware-title": "ПОДДЕРЖИВАЕМОЕ ОБОРУДОВАНИЕ",
      "supported-hardware-description": "Стоимость лицензий меняется по вашему желанию",
      "supported-hardware-button": "СПИСОК МАЙНЕРОВ",
      "banner-title": "Без скрытых платежей",
      "banner-subtitle1": "НИКАКИХ КОМИССИЙ, ПЛАТИТЕ РАЗ В МЕСЯЦ",
      "banner-subtitle2": "ИЛИ ЖЕ ПРИОБРЕТИТЕ ПОЖИЗНЕННУЮ ЛИЦЕНЗИЮ",
      "achive-th-value": "8.4+ млн",
      "achive-th-title": "Увеличили Терахеш",
      "achive-devices-value": "280 000+",
      "achive-devices-title": "Майнеров прошито",
      "achive-clients-value": "7 600",
      "achive-clients-title": "Уникальных клиентов",
      "admin-banner-title": "ПАНЕЛЬ УПРАВЛЕНИЯ",
      "admin-banner-description": "<p>Доступ и функионал вашей заводской системы сохраняются.</p><p>Улучшеный функционал работы с платами, чипами, лог файлами.</p><p>Добавлен функционал настроек и показателей для работы с прошивкой</p>",
      "admin-banner-button": "ПОСМОТРЕТЬ ДЕМО",
      "performance-title": "Ключевые Особенности",
      "performance-description": "До 15% эффективности энергопотребления относительно заводской прошивки",
      "performance-block1-title": "Удобство обновления",
      "performance-block1-description": "Обновление устройства — ваше личное решение. Асик обращается к серверу только при нажатии кнопки.",
      "performance-block2-title": "Защита от перегрева",
      "performance-block2-description": "Устройство автоматически снижает мощность, чтобы не допустить перегрев и продолжать работу.",
      "performance-block3-title": "Отключение вентиляторов",
      "performance-block3-description": "Максимальные режимы производительности и отключение вентиляторов для жидкостного охлаждения.",
      "performance-block4-title": "Интеграция с системами мониторинга",
      "performance-block4-description": "Всегда оставайтесь на связи с вашей фермой, где бы вы не находились. Поддерживаем работу с btc tools.",
      "performance-block5-title": "Вотчдог",
      "performance-block5-description": "Устройство автоматически перезагружается при критических ошибках.",
      "performance-block6-title": "Возврат к заводским настройкам",
      "performance-block6-description": "Вернуться на прошивку от завода — для большинства устройств достаточно просто вынуть флешку.",
      "device-management-title": "Пакетное управление устройствами",
      "device-management-description1": "Модуль, позволяющий менять все настройки прошивки, а так же обновлять устройства принудительно на множестве устройств.",
      "device-management-description2": "Интеграция с BTC Tools не нарушена, можно использовать для мониторинга устройств.",
      "device-management-description3": "Модуль для выгрузки данных по http для осуществления возможности интеграции в существующие системы управления устройств.",
      "device-management-button": "ВСЕ МАЙНЕРЫ",
      "connected-title": "<span>СВЯЗЬ 24 ЧАСА</span>техническая служба доступна всем нашим клиентам",
      "email": "Email",
      "message": "Сообщение",
      "telegram": "TELEGRAM",
      "your-email": "Ваш email...",
      "your-message": "Ваше сообщение...",
      "sent": "ОТПРАВИТЬ",
      "admin-panel-link-title": "ПОПРОБОВАТЬ ДЕМО",
      "admin-panel-description": "Доступ и функионал вашей заводской системы сохраняются. Улучшеный функционал работы с платами, чипами, лог файлами.",
      "copyright": "© 2023 Asteron. All rights reserved.",
      "chips": "chips",
      "see-details": "Детали",
      "faq-title": "Остались вопросы?",
      "faq-subtitle": "Если у вас есть другие вопросы, свяжитесь с нами",
      "faq-button-text": "Добавить вопрос",
      "question-section-title": "Последние заданные вопросы",
      "question-section-subtitle": "Здесь мы постарались собрать ответы на самые частые вопросы",
      "download-section-title": "Скачать",
      "download-section-subtitle": "Используйте изображение для выбора необходимой версии прошивки",
      "download-section-instruction-text": "Инструкция",
      "download-section-download-text": "Скачать",
      "download-section-firmware-text": "Прошивка",
      "energysave-section-text1": "До",
      "energysave-section-text2": "больше хешрейта",
      "energysave-section-text3": "Увеличьте производительность вашего майнера без модификаций оборудования.",
      "energysave-section-text4": "До",
      "energysave-section-text5": "экономия электроэнергии",
      "energysave-section-text6": "Снижайте потребление электроэнергии, без изменения производительности майнера",
      "power-title": "ЭНЕРГОПОТРЕБЛЕНИЕ",
      "power-subtitle": "Данные могут отличаться в зависимости от особенностей оборудования и его расположения.",
      "power-table-title1": "HASHRATE",
      "power-table-title2": "kWt",
      "power-table-stock": "(сток)",
      "resources-title": "Поддерживаемое оборудование",
      "resources-subtitle": "<p>Полный список поддерживаемых майнеров.</p><p>Выберите необходимый вам для полной информации.</p>",
      "faq-home-button": "Смотреть все вопросы",
      "resource-payment-accept-method": "Мы принимаем к оплате",
      "resource-payment-method-1-title": "Ежемесячный платеж",
      "resource-payment-method-2-title": "Ежегодный платеж",
      "resource-payment-method-3-title": "Пожизненная лицензия",
      "resource-payment-period": "Периодичность",
      "resource-price-section-title": "Выберите периодичность оплаты, чтобы узнать цену",
      "resource-payment-method-1": "Пожизненная",
      "resource-payment-method-2": "Ежегодно",
      "resource-payment-method-3": "Ежемесячно"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
