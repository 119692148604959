import styled from 'styled-components';
import Frame from './images/Frame.png';
import LeftShadow from './images/GradientBlue.png';
import RightShadow from './images/GradientGold.png';
import { Col, Row } from '@astrn/common/components/grid';
import { ADAPTIVE } from '@astrn/common/tokens/screen';


export const DeviceManagementStyled = styled.div`
  position: relative;
`;

export const LeftShadowStyled = styled.div`
  background: url(${LeftShadow}) no-repeat;
  min-width: 340px;
  height: 100%;
  flex-shrink: 0;
  position: absolute;
  background-size: contain;

  ${ADAPTIVE.minWidth.tablet} {
    min-width: 500px;
  }
`;

export const RightShadowStyled = styled.div`
  background: url(${RightShadow}) no-repeat;
  min-width: 340px;
  height: 100%;
  flex-shrink: 0;
  position: absolute;
  right: 0;
  top: 0;
  background-size: contain;
  /* transform: rotate(180deg); */

  ${ADAPTIVE.minWidth.tablet} {
    min-width: 500px;
  }
`;

export const RowStyled = styled(Row)`
  ${ADAPTIVE.minWidth.desktop} {
    background: url(${Frame}) no-repeat;
    background-size: contain;
    min-height: 500px;
  }
`;

export const ColStyled = styled(Col)`
  row-gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${ADAPTIVE.maxWidth.tablet} {
    img {
      object-fit: cover;
      width: 100%;
    }
  }
`;