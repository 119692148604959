import React from 'react';
import { ArrowIconStyled, ArrowLine, ArrowLineContainerStyled, CountStyled, ProgressStyled, IconStyled, BoostStyled } from './styled';
import Arrow from './Arrow.svg';
import ArrowBlue from './ArrowBlue.svg';
import Cooler from './Cooler.svg';
import Water from './Water.svg';
import { getCorrectValue } from '@astrn/common/utils/common';

export const Progress = (props: {
  baseValue: string;
  boostValue: string;
  isWater?: boolean;
  maxValue?: number;
  isFullLine?: boolean;
}) => {
  const { baseValue, boostValue, isWater, maxValue, isFullLine = false } = props;

  const getLineWidth = (boostValue: number) => {
    if (maxValue) {
      return boostValue * 100 / maxValue;
    }

    return boostValue;
  }

  return (
    <>
      <ProgressStyled>
        <ArrowLineContainerStyled><ArrowLine lineWidth={isFullLine ? 100 : getLineWidth(Number(boostValue))} isWater={isWater} /><ArrowIconStyled type={isWater ? ArrowBlue : Arrow} sizes={{ w: 16, h: 65 }} /></ArrowLineContainerStyled>
        <CountStyled><IconStyled type={isWater ? Water : Cooler} sizes={{ w: 37, h: 37 }} />{getCorrectValue(Number(baseValue)).value}<span>{getCorrectValue(Number(baseValue)).measurement}</span></CountStyled>
      </ProgressStyled>
      <BoostStyled>{getCorrectValue(Number(boostValue)).value}<span>{getCorrectValue(Number(baseValue)).measurement}</span></BoostStyled>
    </>
  );
}
