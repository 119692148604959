import { HeroSection } from './components/HeroSection';
import { Achievements } from './components/Achievements';
import { SupportedHardware } from './components/SupportedHardware';
import { Performance } from './components/Performance';
// import './components/Temporary/styles.css';
import { Reliability } from './components/Reliability';
import { AdminPanelBanner } from '../../components/AdminPanelBanner';
import { Income } from './components/Income';
import { FAQCarousel } from './components/FAQCarousel';
import { Banner } from './components/Banner';
import { DeviceManagement } from './components/DeviceManagement';
import { Connected } from '../../components/Connected';

function Home() {
  return (
    <>
      <HeroSection />
      <Income />
      <SupportedHardware />
      <Banner />
      <Reliability />
      <Achievements />
      <AdminPanelBanner />
      <Performance />
      <DeviceManagement />
      <FAQCarousel />
      <Connected />
      {/* <div className='light x1'></div>
      <div className='light x2'></div>
      <div className='light x3'></div>
      <div className='light x4'></div>
      <div className='light x5'></div>
      <div className='light x6'></div>
      <div className='light x7'></div>
      <div className='light x8'></div>
      <div className='light x9'></div> */}
    </>
  );
}

export default Home;