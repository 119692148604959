import { useState } from 'react';
import { useParams } from 'react-router-dom';
import data from '../../data/data.json';
import { find, isEmpty, max } from 'lodash';
import { Col, Container, Row } from '@astrn/common/components/grid';
import { CalculatorStyled, CharacteristicsStyled, ChipsStyled, ColStyled, CurrencyStyled, DiscountStyled, ImageStyled, LabelStyled, MethodStyled, NameStyled, PaymentMethodsStyled, PriceColStyled, PriceLabelStyled, PriceSectionTitleStyled, PriceStyled, RowStyled, SelectStyled } from './styled';
import { Progress } from '@astrn/components/Progress';
import { Image } from '@astrn/common/components/image';
import ResourceImage from '@astrn/assets/resource.png';
import BTCIcon from '@astrn/assets/icons/btc.png';
import USDTIcon from '@astrn/assets/icons/usdt.png'
import { DownloadSection } from './components/DownloadSection';
import { Connected } from '@astrn/components/Connected';
import { AdministratorPanel } from '@astrn/components/AdministratorPanel';
import { useWindowSize } from '@astrn/common/hooks';
import { FAQ } from './components/FAQ';
import { Breadcrumbs } from './components/Breadcrumbs';
import { PowerCompsumption } from './components/PowerConsumption';
import { Achievements } from '../Home/components/Achievements';
import { DeviceManagement } from '../Home/components/DeviceManagement';
import { EnergySave } from './components/EnergySave';
import { useTranslation } from 'react-i18next';


export function Resource() {
  const { id } = useParams();
  const { t } = useTranslation();

  const options = [
    { value: 'lifetime', label: t('resource-payment-method-1')},
    { value: 'annual', label: t('resource-payment-method-2')},
    { value: 'monthly', label: t('resource-payment-method-3')}
  ]

  const [priceType, setPriceType] = useState<string>(options[2].value)

  const { isMobileView } = useWindowSize();

  const resource = find(data, ['id', id]);

  if (isEmpty(resource)) return null;

  return (
    <>
      <Container lgPb='80px'>
        <Row>
          <Col lg={6} style={{ overflow: 'hidden' }}>
            <Breadcrumbs title={resource?.name} />
          </Col>
        </Row>
        <Row>
          <Col lg={7} order={isMobileView ? 2 : 1}>
            <NameStyled>
              {resource?.name}
              {resource?.countChips && <ChipsStyled>
                {resource?.countChips} {t('chips')}
              </ChipsStyled>}
              <span>{resource?.variants}</span>
            </NameStyled>
            <CharacteristicsStyled>
              {resource?.baseValueCooler
                && <Progress
                baseValue={resource?.baseValueCooler}
                boostValue={resource?.boostValueCooler}
                maxValue={
                  max([Number(resource?.boostValueCooler), Number(resource?.boostValueWater)])
                }
              />}
            </CharacteristicsStyled>
            <CharacteristicsStyled>
              {resource?.baseValueWater
                && <Progress
                  baseValue={resource?.baseValueWater}
                  boostValue={resource?.boostValueWater}
                  maxValue={
                    max([Number(resource?.boostValueCooler), Number(resource?.boostValueWater)])
                  }
                  isWater
                />
              }
            </CharacteristicsStyled>
          </Col>
          <ColStyled lg={5} justifyContent='center' order={isMobileView ? 1 : 2}>
            <ImageStyled src={ResourceImage} width={isMobileView ? 340 : 396} height={isMobileView ? 300 : 344} />
          </ColStyled>
        </Row>
        <PriceSectionTitleStyled>
          {t('resource-price-section-title')}
        </PriceSectionTitleStyled>
        <CalculatorStyled>
          <RowStyled rowGap='16px'>
            <Col lg={4} md={8} sm={4}>
              <LabelStyled>
                {t('resource-payment-period')}
              </LabelStyled>
              <SelectStyled
                className="basic-single"
                classNamePrefix="select"
                defaultValue={options[2]}
                options={options}
                // @ts-ignore
                autoComplete="off"
                inputMode="none"
                onChange={(priceType: any) => setPriceType(priceType.value)}
              />
            </Col>
            {/* <Col lg={2} md={8} sm={4}>
              <LabelStyled>
                Number of devices
              </LabelStyled>
              <MinusButtonStyled onClick={() => handleClick(count - 1)}>
                <SVGIcon type={Minus} sizes={{
                  w: 24,
                  h: 24
                }} />
              </MinusButtonStyled>
              <InputStyled type="text" value={count} onChange={({ target }) => handleClick(Number(target.value))} />
              <PlusButtonStyled onClick={() => handleClick(count + 1)}>
                <SVGIcon type={Plus} sizes={{
                    w: 24,
                    h: 24
                  }} />
              </PlusButtonStyled>
            </Col> */}
            <PriceColStyled lg={8} md={8} sm={4}>
              {/* @ts-ignore */}
              {Number(resource?.prices[priceType].discount) > 0 && <DiscountStyled><CurrencyStyled>$</CurrencyStyled>{Number(resource?.prices[priceType].price) * 1}</DiscountStyled>}
              {priceType === 'monthly' && <PriceLabelStyled>{t('resource-payment-method-1-title')}:</PriceLabelStyled>}
              {priceType === 'annual' && <PriceLabelStyled>{t('resource-payment-method-2-title')}:</PriceLabelStyled>}
              {priceType === 'lifetime' && <PriceLabelStyled>{t('resource-payment-method-3-title')}:</PriceLabelStyled>}
              <PriceStyled>
                {/* @ts-ignore */}
                <CurrencyStyled>$</CurrencyStyled>{(Number(resource?.prices[priceType].price) * 1) - Number(resource?.prices[priceType].discount || 0)}
              </PriceStyled>
            </PriceColStyled>
          </RowStyled>
        </CalculatorStyled>
        <PaymentMethodsStyled>
          <p>{t('resource-payment-accept-method')}:</p>
          <MethodStyled color="#FFC107"><Image src={BTCIcon} width={36} height={36} /> BTC</MethodStyled>
          <MethodStyled color="#26A69A"><Image src={USDTIcon} width={36} height={36} /> USDT TRC-20</MethodStyled>
        </PaymentMethodsStyled>
      </Container>
      <DownloadSection />
      <PowerCompsumption />
      <Achievements />
      <EnergySave />
      <AdministratorPanel />
      <DeviceManagement />
      <FAQ />
      <Connected />
    </>
  );
}
