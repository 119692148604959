import { Container, Row } from '@astrn/common/components/grid';
import { ColStyled, HighlightedTextStyled } from './styled';
import { SubHighlightedText } from '@astrn/common/tokens/typography';
import { useTranslation } from 'react-i18next';

export function Banner() {

  const { t } = useTranslation();

  return (
    <Container>
      <Row lgMt='120px' lgMb='120px' mdMt='80px' mdMb='80px' mt='60px' mb='60px'>
        <ColStyled>
          <HighlightedTextStyled>
            {t('banner-title')}
            <SubHighlightedText>{t('banner-subtitle1')}</SubHighlightedText>
            <SubHighlightedText>{t('banner-subtitle2')}</SubHighlightedText>
          </HighlightedTextStyled>
        </ColStyled>
      </Row>
    </Container>
  );
}