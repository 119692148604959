import styled, { createGlobalStyle } from 'styled-components';
import MainGradient from './assets/gradients/main-gradient.svg'

export const GlobalStyles = createGlobalStyle`
  html {
    min-width: 320px;
    overflow: auto;
  }

  body {
    font-family: 'Montserrat';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    color: #fff;
    margin: 0;
    line-height: normal;
    background-color: #000;

    #root {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
    }


    // =======================================
    background: #000;
    -webkit-overflow-Y: hidden;
    -moz-overflow-Y: hidden;
    -o-overflow-Y: hidden;
    overflow-y: hidden;
    -webkit-animation: fadeIn 1 1s ease-out;
    -moz-animation: fadeIn 1 1s ease-out;
    -o-animation: fadeIn 1 1s ease-out;
    animation: fadeIn 1 1s ease-out;
  }

  /* .light {
    position: absolute;
    width: 0px;
    opacity: .15;
    background-color: #ccc;
    box-shadow: #e9f1f1 0px 0px 20px 2px;
    opacity: 0;
    top: 100vh;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    z-index: 0;
}

.x1{
  -webkit-animation: floatUp 4s infinite linear;
  -moz-animation: floatUp 4s infinite linear;
  -o-animation: floatUp 4s infinite linear;
  animation: floatUp 4s infinite linear;
   -webkit-transform: scale(1.0);
   -moz-transform: scale(1.0);
   -o-transform: scale(1.0);
  transform: scale(1.0);
}

.x2{
  -webkit-animation: floatUp 7s infinite linear;
  -moz-animation: floatUp 7s infinite linear;
  -o-animation: floatUp 7s infinite linear;
  animation: floatUp 7s infinite linear;
  -webkit-transform: scale(1.6);
  -moz-transform: scale(1.6);
  -o-transform: scale(1.6);
  transform: scale(1.6);
  left: 15%;
}

.x3{
  -webkit-animation: floatUp 2.5s infinite linear;
  -moz-animation: floatUp 2.5s infinite linear;
  -o-animation: floatUp 2.5s infinite linear;
  animation: floatUp 2.5s infinite linear;
  -webkit-transform: scale(.5);
  -moz-transform: scale(.5);
  -o-transform: scale(.5);
  transform: scale(.5);
  left: -15%;
}

.x4{
  -webkit-animation: floatUp 4.5s infinite linear;
  -moz-animation: floatUp 4.5s infinite linear;
  -o-animation: floatUp 4.5s infinite linear;
  animation: floatUp 4.5s infinite linear;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  left: -34%;
}

.x5{
  -webkit-animation: floatUp 8s infinite linear;
  -moz-animation: floatUp 8s infinite linear;
  -o-animation: floatUp 8s infinite linear;
  animation: floatUp 8s infinite linear;
  -webkit-transform: scale(2.2);
  -moz-transform: scale(2.2);
  -o-transform: scale(2.2);
  transform: scale(2.2);
  left: -57%;
}

.x6{
  -webkit-animation: floatUp 3s infinite linear;
  -moz-animation: floatUp 3s infinite linear;
  -o-animation: floatUp 3s infinite linear;
  animation: floatUp 3s infinite linear;
  -webkit-transform: scale(.8);
  -moz-transform: scale(.8);
  -o-transform: scale(.8);
  transform: scale(.8);
  left: -81%;
}

.x7{
  -webkit-animation: floatUp 5.3s infinite linear;
  -moz-animation: floatUp 5.3s infinite linear;
  -o-animation: floatUp 5.3s infinite linear;
  animation: floatUp 5.3s infinite linear;
  -webkit-transform: scale(3.2);
  -moz-transform: scale(3.2);
  -o-transform: scale(3.2);
  transform: scale(3.2);
  left: 37%;
}

.x8{
  -webkit-animation: floatUp 4.7s infinite linear;
  -moz-animation: floatUp 4.7s infinite linear;
  -o-animation: floatUp 4.7s infinite linear;
  animation: floatUp 4.7s infinite linear;
  -webkit-transform: scale(1.7);
  -moz-transform: scale(1.7);
  -o-transform: scale(1.7);
  transform: scale(1.7);
  left: 62%;
}

.x9{
  -webkit-animation: floatUp 4.1s infinite linear;
  -moz-animation: floatUp 4.1s infinite linear;
  -o-animation: floatUp 4.1s infinite linear;
  animation: floatUp 4.1s infinite linear;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  left: 85%;
}

@-webkit-keyframes floatUp{
  0%{top: 100vh; opacity: 0;}
  25%{opacity: 1;}
  50%{top: 0vh; opacity: .8;}
  75%{opacity: 1;}
  100%{top: -100vh; opacity: 0;}
}
@-moz-keyframes floatUp{
  0%{top: 100vh; opacity: 0;}
  25%{opacity: 1;}
  50%{top: 0vh; opacity: .8;}
  75%{opacity: 1;}
  100%{top: -100vh; opacity: 0;}
}
@-o-keyframes floatUp{
  0%{top: 100vh; opacity: 0;}
  25%{opacity: 1;}
  50%{top: 0vh; opacity: .8;}
  75%{opacity: 1;}
  100%{top: -100vh; opacity: 0;}
}
@keyframes floatUp{
  0%{top: 100vh; opacity: 0;}
  25%{opacity: 1;}
  50%{top: 0vh; opacity: .8;}
  75%{opacity: 1;}
  100%{top: -100vh; opacity: 0;}
} */

  :root {

    /* fonts */
    --header-h5: Montserrat;
    --display-lg-medium: Poppins;

    /* font sizes */
    --scale-xs-size: 12px;
    --text-md-regular-size: 16px;
    --text-sm-regular-size: 14px;
    --header-h5-size: 20px;
    --header-h1-size: 48px;
    --display-xs-medium-size: 24px;
    --header-h2-size: 36px;
    --scale-lg-size: 18px;

    /* Colors */
    --colors-background: #000;
    --colors-primary: #ffb22e;
    --color-silver: #bfbfbf;
    --palette-gray-grey-800: #1f1f1f;
    --gray-5001: #667085;
    --gray-500: #556987;
    --colors-text-primary: #fff;
    --gray-200: #d5dae1;
    --gray-700: #404f65;
    --gray-800: #333f51;
    --gray-900: #2a3342;
    --colors-text-secondary: #f0f0f0;
    --gray-400: #8896ab;

    /* Gaps */
    --gap-5xs: 8px;
    --gap-5xl: 24px;
    --gap-7xs: 6px;
    --gap-9xs: 4px;
    --gap-29xl: 48px;
    --gap-45xl: 64px;

    /* Paddings */
    --padding-xs: 12px;
    --padding-lg: 18px;
    --padding-3xs: 10px;
    --padding-sm: 14px;
    --padding-13xl: 32px;
    --padding-2xl: 21px;
    --padding-base: 16px;
    --padding-9xl: 28px;
    --padding-5xs: 8px;

    /* Border radiuses */
    --br-7xs: 6px;
    --br-5xs: 8px;
    --br-7xs-3: 5.3px;
  }`;

  export const SectionStyled = styled.section`
    background: url('${MainGradient}') no-repeat;
    background-position: top center;
  `;
