import { Col } from '@astrn/common/components/grid';
import { HighlightedText } from '@astrn/common/tokens/typography';
import styled from 'styled-components';

export const ColStyled = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const HighlightedTextStyled = styled(HighlightedText)`
  display: flex;
  flex-direction: column;
`;
