import { Col, Row } from '@astrn/common/components/grid';
import { CenterColStyled, ContainerStyled, CountStyled, CountTextStyled, RowStyled, TitleStyled } from './styled';
import { useTranslation } from 'react-i18next';

export function Achievements() {

  const { t } = useTranslation();

  return (
    <ContainerStyled pb='60px' mdPb='60px' lgPb='120px'>
      <RowStyled lgMt='70px' id='achievements'>
        <Col lg={1} />
        <CenterColStyled lg={10}>
          <TitleStyled
            dangerouslySetInnerHTML={{__html: t("achive-text") }}
          />
        </CenterColStyled>
        <Col lg={1} />
      </RowStyled>
      <Row rowGap='26px'>
        <CenterColStyled lg={4} md={8} sm={4}>
          <CountStyled>{t('achive-th-value')}</CountStyled>
          <CountTextStyled>{t('achive-th-title')}</CountTextStyled>
        </CenterColStyled>
        <CenterColStyled lg={4} md={8} sm={4}>
          <CountStyled>{t('achive-devices-value')}</CountStyled>
          <CountTextStyled>{t('achive-devices-title')}</CountTextStyled>
        </CenterColStyled>
        <CenterColStyled lg={4} md={8} sm={4}>
          <CountStyled>{t('achive-clients-value')}</CountStyled>
          <CountTextStyled>{t('achive-clients-title')}</CountTextStyled>
        </CenterColStyled>
      </Row>
    </ContainerStyled>
  );
}
