import {
  SectionStyled,
  ContainerStyled,
  // HeadingLevel1Styled,
  DescriptionStyled,
  HeroSectionStyled,
  LinesLayerStyled,
  FoundationLayerStyled,
  MinerLayerStyled,
  // ColStyled,
  // Animate1Styled,
  SubhighlightedTextStyled,
} from "./styled";
import { Col, Row } from '@astrn/common/components/grid';
// import { BannerLayer } from "react-scroll-parallax";
import { useTranslation } from "react-i18next";
import { ButtonStyled } from "@astrn/common/components/button-styled";
import { useWindowSize } from "@astrn/common/hooks";
// import { getFoundationY, getLinesXY, getMinerXY, getTextXY } from "./helpers";

import './styles.css';
import { HighlightedText } from "@astrn/common/tokens/typography";
import { Link } from "react-router-dom";

export function HeroSection() {
  const { t } = useTranslation();
  const { isMobileView, isTabletView, isMiddleDesktopView, isDesktopView } = useWindowSize();
  
  return (
    <>
      <HeroSectionStyled>
        <ContainerStyled height={isMobileView ? "auto" : "900px"}>
          {isMobileView ? (
            <>
              <LinesLayerStyled
                isMobileView={isMobileView}
                isTabletView={isTabletView}
                isMiddleDesktopView={isMiddleDesktopView}
              />
              <MinerLayerStyled
                isMobileView={isMobileView}
                isTabletView={isTabletView}
                isMiddleDesktopView={isMiddleDesktopView}
                isDesktopView={isDesktopView}
              />
              <FoundationLayerStyled isTabletView={isTabletView} />
            </>
          ) : <>
              <LinesLayerStyled
                isMobileView={isMobileView}
                isTabletView={isTabletView}
                isMiddleDesktopView={isMiddleDesktopView}
              />
              <FoundationLayerStyled isTabletView={isTabletView} />
              <MinerLayerStyled
                isMobileView={isMobileView}
                isTabletView={isTabletView}
                isMiddleDesktopView={isMiddleDesktopView}
                isDesktopView={isDesktopView}
              />
            </>}
          <Row lgMt="70px" style={{ zIndex: 5 }}>
            <Col lg={10}>
              <SectionStyled>
                <HighlightedText isBold>
                  {t('home-hero-title1')}
                  {/* {!isInfiniteDesktopView && <wbr/>} */}
                  <div dangerouslySetInnerHTML={{ __html: t('home-hero-title2') }} />
                </HighlightedText>
                <SubhighlightedTextStyled color="#FFFFFF" mt>
                  {t('home-hero-title3')}
                </SubhighlightedTextStyled>
                <SubhighlightedTextStyled color="#FFB22E">
                  {t('home-hero-title4')}
                </SubhighlightedTextStyled>
                <DescriptionStyled>{t("home-hero-description")}</DescriptionStyled>
                <ButtonStyled zIndex="5"><Link to="/resources">{t("home-hero-button")}</Link></ButtonStyled>
              </SectionStyled>
            </Col>
            <Col lg={2}>
            </Col>
          </Row>
        </ContainerStyled>
      </HeroSectionStyled>
    </>
  );
}
