import React, { useState } from 'react';
import { Col, Container, Row } from '@astrn/common/components/grid';
import { ColStyled, FormRowStyled, FormStyled, SocialStyled, SocialTextStyled } from './styled';
import { ButtonStyled } from '@astrn/common/components/button-styled';
import { SVGIcon } from '@astrn/common/components/svg-icon';
import EmailIcon from '@astrn/assets/icons/email.svg';
import TelegramIcon from '@astrn/assets/icons/telegram.svg';
import { HeadingLevel3, NormalHighlightedText, RegularTextLevel1 } from '@astrn/common/tokens/typography';
import { useWindowSize } from '@astrn/common/hooks';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';

export function Connected() {

  const { t } = useTranslation();
  const { isTabletView } = useWindowSize();

  return (
    <Container lgPt='80px' lgPb='80px' pt='40px' pb='80px' id="contacts">
      <Row rowGap='24px'>
        <Col lg={6} md={3} sm={4}>
          <NormalHighlightedText dangerouslySetInnerHTML={{__html: t('connected-title') }} />
          <Row mt='24px' rowGap={isTabletView ? '32px' : '0'}>
            <ColStyled lg={6} sm={2}>
              <SocialStyled>
                <SVGIcon type={EmailIcon} sizes={{
                  w: 48,
                  h: 48
                }} />
              </SocialStyled>
              <SocialTextStyled>
                <HeadingLevel3>
                  {t('email')}
                </HeadingLevel3>
                <RegularTextLevel1 color='#fff'>
                  info@asteron.io
                </RegularTextLevel1>
              </SocialTextStyled>
            </ColStyled>
            <ColStyled lg={6} sm={2}>
              <SocialStyled>
                <SVGIcon type={TelegramIcon} sizes={{
                  w: 48,
                  h: 48
                }} />
              </SocialStyled>
              <SocialTextStyled>
                <HeadingLevel3>
                  {t('telegram')}
                </HeadingLevel3>
                <RegularTextLevel1 color='#fff'>
                  @jonnyfin
                </RegularTextLevel1>
              </SocialTextStyled>
            </ColStyled>
          </Row>
        </Col>
        <Col lg={6} md={5} sm={4}>
          <FormStyled>
            <FormRowStyled>
              <label>{t('email')}</label>
              <input type='text' placeholder={t('your-email')} />
            </FormRowStyled>
            <FormRowStyled>
              <label>{t('message')}</label>
              <textarea placeholder={t('your-message')} />
            </FormRowStyled>
            <ButtonStyled type='large' btnWidth={'100%'}>
              {t('sent')}
            </ButtonStyled>
          </FormStyled>
        </Col>
      </Row>
    </Container>
  );
}
