import { Col, Container, Row } from '@astrn/common/components/grid';
import Accordion from '@astrn/common/components/accordion';
import { HeadingLevel2 } from '@astrn/common/tokens/typography';
import { useTranslation } from 'react-i18next';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import FAQData from '@astrn/data/faq.json';

export function FAQ() {

  const { t, i18n } = useTranslation();

  return (
    <Container>
        <Row lgMt='80px' lgMb='80px' mt='40px' mb='40px'>
          <Col lg={4}>
            <HeadingLevel2 width='100%'>{t('question-section-title')}</HeadingLevel2>
          </Col>
          <Col lg={8}>
            {/* @ts-ignore */}
            {filter(FAQData[i18n.language], (question) => includes([1, 6, 8], question.id)).map((item: any, index: number) => (
              <Accordion
                title={item.question}
                content={item.answer}
              />
            ))}
          </Col>
        </Row>
      </Container>
  );
}