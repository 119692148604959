import { ButtonStyled } from '@astrn/common/components/button-styled';
import { Row } from '@astrn/common/components/grid';
import { Image } from '../../common/components/image';
import { HeadingLevel3, RegularTextLevel1 } from '../../common/tokens/typography';
import { AdminPanelBannerStyled, ColDeviceStyled, ColStyled, ContainerStyled, ImageStyled } from './styled';
import LargeTablet from '../../assets/admin-panel-section/large-tablet.png';
import { RightShadowStyled } from '../AdministratorPanel/styled';
import { useWindowSize } from '../../common/hooks';
import { useTranslation } from 'react-i18next';

export function AdminPanelBanner() {

  const { t } = useTranslation();
  const { isMobileView } = useWindowSize();

  return (
    <AdminPanelBannerStyled>
      {/* {!isMobileView && !isTabletView && <LeftShadowStyled />} */}
      <ContainerStyled>
        <Row>
          <ColStyled lg={5} md={3} sm={4}>
            <HeadingLevel3 color='#FFB22E'>
              {t('admin-banner-title')}
            </HeadingLevel3>
            <RegularTextLevel1 color='#FFFFFF' dangerouslySetInnerHTML={{ __html: t('admin-banner-description') }} />
            <ButtonStyled type={isMobileView ? 'small' : 'large'}>
              {t('admin-banner-button')}
            </ButtonStyled>
          </ColStyled>
          <ColDeviceStyled lg={7} md={5} sm={4}>
            <ImageStyled>
              <Image src={LargeTablet} />
            </ImageStyled>
          </ColDeviceStyled>
        </Row>
      </ContainerStyled>
      <RightShadowStyled />
    </AdminPanelBannerStyled>
  )
}
