import React from 'react'
import { ContainerStyled, ResourcesPageStyled, RowStyled, SubTitleStyled, TitleStyled } from './styled';
import { Col, Row } from '../../common/components/grid';
import { HeadingLevel1, RegularTextLevel3 } from '../../common/tokens/typography';
import resources from '../../data/data.json';
import Accordion from '../../common/components/accordion';
import { AccordionMainTitleStyled } from '../../common/components/accordion/styled';
import { useWindowSize } from '../../common/hooks';
import { Image } from '../../common/components/image';
import Antminer from '../../assets/resource/antminer.png';
import { ResourcesList } from '../../components/Resources';
import { useTranslation } from 'react-i18next';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import FAQData from '@astrn/data/faq.json';

function Resources() {
  const { isMobileView } = useWindowSize();
  const { t, i18n } = useTranslation();
  
  return (
    <ResourcesPageStyled>
      <ContainerStyled width='100%'>
        <RowStyled>
          <Col lg={7} md={4} sm={4} smCustomMargin='0 0 40px' order={isMobileView ? 2 : 1}>
            <TitleStyled>
              <HeadingLevel1 color='#fff'>{t('resources-title')}</HeadingLevel1>
            </TitleStyled>
            <SubTitleStyled>
              <RegularTextLevel3 color='#f0f0f0'
                dangerouslySetInnerHTML={{ __html: t('resources-subtitle') }}              
              />
            </SubTitleStyled>
          </Col>
          <Col lg={5} md={4} order={isMobileView ? 1 : 2}>
            <Image src={Antminer} />
          </Col>
        </RowStyled>
        <Row lgMb='80px'>
          <Col>
            <ResourcesList resources={resources} />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <AccordionMainTitleStyled>{t('question-section-title')}</AccordionMainTitleStyled>
          </Col>
          <Col lg={8}>
            {/* @ts-ignore */}
            {filter(FAQData[i18n.language], (question) => includes([1, 6, 8], question.id)).map((item: any, index: number) => (
              <Accordion
                title={item.question}
                content={item.answer}
              />
            ))}
          </Col>
        </Row>
      </ContainerStyled>
    </ResourcesPageStyled>
  );
}

export default Resources;
