import styled from 'styled-components';
import { HeadingLevel2, HeadingLevel3 } from '../../../../common/tokens/typography';
import { getTextStyles } from '../../../../common/utils/styles';
import { Col, Row } from '../../../../common/components/grid';
import { ADAPTIVE } from '../../../../common/tokens/screen';

export const TitleStyled = styled(HeadingLevel2)`
  margin-top: 80px;
  margin-bottom: 16px;
  text-align: center;
`;

export const SubTitleStyled = styled.p`
  ${getTextStyles('Montserrat', 400, 16, 24)}
  text-align: center;
`;

export const DownloadFileStyled = styled(Row)`
  padding: 24px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: #141414;
  margin: 0 auto 16px auto;
  border: 1px solid transparent;

  ${ADAPTIVE.minWidth.tablet} {
    padding: 36px;
  }

  &:hover {
    border: 1px solid #FFB22E;
    cursor: pointer;
  }
`;

export const LeftColStyled = styled(Col)`
  ${getTextStyles('Montserrat', 700, 20, 30)}
`;

export const RightColStyled = styled(Col)`
  ${getTextStyles('Montserrat', 700, 20, 30)}
  display: inline-flex;
  text-align: right;
  justify-content: flex-end;
  color: #FFB22E;
  gap: 8px;
  cursor: pointer;
`;

export const FirmwareTitleStyled = styled(HeadingLevel3)`
  color: #FFF;
  text-align: center;
  margin: 50px 0 32px 0;
  ${getTextStyles('Montserrat', 700, 30, 38)}
`;

export const ImageStyled = styled.img`
  width: 100%;
  border-radius: 8px;;
`;

export const DownloadLinkStyled = styled.a`
  text-decoration: none;
  color: #FFFFFF;
  ${getTextStyles('Montserrat', 400, 16, 24)}

  ${ADAPTIVE.minWidth.tablet} {
    ${getTextStyles(null, 700, 18, 28)}
  }

  ${ADAPTIVE.minWidth.desktop} {
    ${getTextStyles(null, 700, 20, 30)}
  }
`;
