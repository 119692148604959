import React from 'react';
import { Col, Container, Row } from '@astrn/common/components/grid';
import { DownloadFileStyled, DownloadLinkStyled, FirmwareTitleStyled, ImageStyled, LeftColStyled, RightColStyled, TitleStyled } from './styled';
import { SubTitleStyled } from './styled';
import { SVGIcon } from '@astrn/common/components/svg-icon';
import ArrowRight from '@astrn/assets/icons/arrow-right.svg';
import { RegularTextLevel1 } from '@astrn/common/tokens/typography';
import data from '@astrn/data/data.json';
import { useParams } from 'react-router-dom';
import { find } from 'lodash';
import { FILE_SERVER } from '@astrn/common/endpoints';
import { useTranslation } from 'react-i18next';

export function DownloadSection() {
  const { id } = useParams();
  const { t, i18n } = useTranslation();

  const resource = find(data, ['id', id]);

  return (
    <Container lgPb='80px' pb='40px'>
      <Row>
        <Col>
          <TitleStyled>{t('download-section-title')}</TitleStyled>
          <SubTitleStyled>{t('download-section-subtitle')}</SubTitleStyled>
        </Col>
      </Row>
      {resource?.firmware.map((item: any) => (
        <>
          <FirmwareTitleStyled>{item.title}</FirmwareTitleStyled>
          <Row>
            <Col lg={1} />
            <Col lg={4}>
              <ImageStyled src={`${FILE_SERVER}/data/images/AML.jpg`} alt="" />
            </Col>
            <Col lg={6}>
              <DownloadLinkStyled href={`${FILE_SERVER}${item.manualUrl[i18n.language]}`} download>
                <DownloadFileStyled lgMb='16px'>
                  <LeftColStyled lg={6}>{t('download-section-instruction-text')} {item.title}</LeftColStyled>
                  <RightColStyled lg={6}><RegularTextLevel1 color='#FFB22E' weight={700} isDisplayFlex alignItems='center' gap='8px'>
                    {t('download-section-download-text')}
                    <SVGIcon sizes={{
                      w: 24,
                      h: 28
                    }} type={ArrowRight} /></RegularTextLevel1></RightColStyled>
                </DownloadFileStyled>
              </DownloadLinkStyled>
              <DownloadLinkStyled href={`${FILE_SERVER}${item.firmwareUrl}`} download>
                <DownloadFileStyled lgMb='16px'>
                  <LeftColStyled lg={6}>{t('download-section-firmware-text')} {item.title}</LeftColStyled>
                  <RightColStyled lg={6}>
                    <RegularTextLevel1 color='#FFB22E' weight={700} isDisplayFlex alignItems='center' gap='8px'>
                      {t('download-section-download-text')}
                      <SVGIcon sizes={{
                        w: 24,
                        h: 28
                      }} type={ArrowRight} />
                    </RegularTextLevel1>
                  </RightColStyled>
                </DownloadFileStyled>
              </DownloadLinkStyled>
            </Col>
            <Col lg={1} />
          </Row>
        </>
      ))}
    </Container>
  );
}
