import React from 'react';
import { Col, Container, Row } from '../../../../common/components/grid';
import resources from '@astrn/data/data.json';
import { ButtonColStyled, ButtonLinkStyled } from './styled';
import { ResourcesList } from '../../../../components/Resources';
import { HeadingLevel2, RegularTextLevel1 } from '../../../../common/tokens/typography';
import { useTranslation } from 'react-i18next';
import filter from 'lodash/filter';
import includes from 'lodash/includes';

export function SupportedHardware() {

  const { t } = useTranslation();

  return (
    <Container lgPb='80px' lgPt='80px'>
      <Row>
        <Col>
          <HeadingLevel2 textAlign='center'>
            {t('supported-hardware-title')}
          </HeadingLevel2>
          <RegularTextLevel1 textAlign='center' color='#fff'>
            {t('supported-hardware-description')}
          </RegularTextLevel1>
        </Col>
      </Row>
      <Row mt='24px' mdMt='36px' lgMt='36px'>
        <Col>
          <ResourcesList resources={filter(resources, (res) => includes(['s19xp', 's19jpro', 's21'], res.id))} />
        </Col>
      </Row>
      <Row>
        <ButtonColStyled>
          <ButtonLinkStyled to="/resources">
            {t('supported-hardware-button')}
          </ButtonLinkStyled>
        </ButtonColStyled>
      </Row>
    </Container>
  );
}