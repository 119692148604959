import styled from "styled-components";

export const LineStyled = styled.div`
  background-color: #1F1F1F;
  width: 100%;
  height: 1px;
  margin: 50px 0;
`;

export const FooterStyled = styled.footer`
  margin-top: auto;
`;