import { Container } from "@astrn/common/components/grid";
import { ColStyled, DeviceManagementStyled, LeftShadowStyled, RightShadowStyled, RowStyled } from "./styled";
import { HeadingLevel4, RegularTextLevel1 } from "@astrn/common/tokens/typography";
import { ButtonStyled } from "@astrn/common/components/button-styled";
import { useWindowSize } from "@astrn/common/hooks/useWindowSize";
import Frame from './images/Frame.png';
import { useTranslation } from "react-i18next";

export function DeviceManagement() {

  const { t } = useTranslation();
  const { isInfiniteDesktopView, isTabletView, isMobileView } = useWindowSize();

  return (
    <DeviceManagementStyled>
      <LeftShadowStyled />
      <Container>
        <RowStyled lgMt="150px" lgMb="100px" mdMt="100px" mdMb="100px" mt="80px" mb="100px">
          <ColStyled lg={6} md={8} sm={4} order={(isMobileView || isTabletView) ? 2 : 1}>
            {(isMobileView || isTabletView) && <img src={Frame} alt="" />}
          </ColStyled>
          <ColStyled lg={6} md={8} sm={4} order={(isMobileView || isTabletView) ? 1 : 2}>
            <HeadingLevel4 color="#FFB22E">{t('device-management-title')}</HeadingLevel4>
            <RegularTextLevel1>{t('device-management-description1')}</RegularTextLevel1>
            <RegularTextLevel1>{t('device-management-description2')}</RegularTextLevel1>
            <RegularTextLevel1>{t('device-management-description3')}</RegularTextLevel1>
            {isInfiniteDesktopView && <ButtonStyled style={{ width: "280px", marginTop: "auto" }}>
            {t('device-management-button')}
            </ButtonStyled>}
          </ColStyled>
        </RowStyled>
      </Container>
      {!isMobileView && <RightShadowStyled />}
    </DeviceManagementStyled>
  );
}