import { ColStyled, ColValueStyled, ContainerStyled, GradientLayerStyled, HeadingStyled, ImageStyled, LineStyled, RegularTextStyled, ReliabilityStyled, ValueStyled, ValueTextStyled } from "./styled";
import { Col, Row } from "../../../../common/components/grid";

import { BannerLayer } from "react-scroll-parallax";
import { useState } from 'react';
import { useWindowSize } from '@astrn/common/hooks';
import { useTranslation } from 'react-i18next';
import OneLineR from './images/OnelineR.svg';

export function Reliability() {

  const { t } = useTranslation();

  const [scroll, setScroll] = useState(0);
  const [deg, setDeg] = useState(15);

  const { isInfiniteDesktopView, isMiddleDesktopView, isTabletView, isMobileView } = useWindowSize();

  window.addEventListener('scroll', () => {
    if (window.scrollY > (scroll + 100)) {
      setScroll(window.scrollY);
      setDeg(deg >= 360 ? 15 : (deg + 15));
    }
  });

  return (
    <ReliabilityStyled>
      <ContainerStyled lgPt="80px" lgPb="80px" mdPt="60px" mdPb="60px" pt="60px" pb="60px">
        {/* <ParallaxBanner
          style={isMobileView ? { aspectRatio: '1 / 2' } : { aspectRatio: '2 / 1' }}
          layers={[layer2, layer1, layer3]}
        /> */}
        <GradientLayerStyled />
        <LineStyled>
          <ImageStyled src={OneLineR} isFullHeight={false} />
        </LineStyled>
        {isMobileView
        ? <Row rowGap='100px'>
          <ColValueStyled sm={4}>
            <ValueTextStyled>
              {t('efficiency-upto-text')}
            </ValueTextStyled>
            <ValueStyled deg={deg}>
              {t('efficiency-value-text')}
            </ValueStyled>
            <ValueTextStyled>
              {t('efficiency-hashrate-increase-text')}
            </ValueTextStyled>
            <RegularTextStyled color='#BFBFBF'>
              {t('efficiency-description-text')}
            </RegularTextStyled>
          </ColValueStyled>
          <ColStyled sm={4}>
            <HeadingStyled>
              {t('efficiency-overclocking-text')}
            </HeadingStyled>
            <RegularTextStyled color='#BFBFBF' dangerouslySetInnerHTML={{ __html: t('efficiency-overclocking-description-text') }} />
          </ColStyled>
        </Row>
        : <Row style={{ paddingTop: '50px', zIndex: '2', position: 'relative' }}>
          {(isInfiniteDesktopView || isMiddleDesktopView) && <Col lg={1} />}
          
          <ColStyled lg={5} md={3}>
            <HeadingStyled>
              {t('efficiency-overclocking-text')}
            </HeadingStyled>
            <RegularTextStyled color='#BFBFBF' dangerouslySetInnerHTML={{ __html: t('efficiency-overclocking-description-text') }}/>
          </ColStyled>
          {(isInfiniteDesktopView || isMiddleDesktopView || isTabletView) && <Col lg={1} md={2} />}
          <ColValueStyled lg={4} md={3}>
            <ValueTextStyled>
              {t('efficiency-upto-text')}
            </ValueTextStyled>
            <ValueStyled deg={deg}>
              {t('efficiency-value-text')}
            </ValueStyled>
            <ValueTextStyled>
              {t('efficiency-hashrate-increase-text')}
            </ValueTextStyled>
            <RegularTextStyled color='#BFBFBF'>
              {t('efficiency-description-text')}
            </RegularTextStyled>
          </ColValueStyled>
          
          {(isInfiniteDesktopView || isMiddleDesktopView) && <Col lg={1} md={1} />}
        </Row>}
      </ContainerStyled>
    </ReliabilityStyled>
  );
}
