import { ColValueStyled, ContainerStyled, GradientLayerStyled, ImageStyled, LineStyled, RegularTextStyled, ReliabilityStyled, ValueStyled, ValueTextStyled } from "./styled";
import { Col, Row } from "@astrn/common/components/grid";

import { useState } from 'react';
import { useWindowSize } from '@astrn/common/hooks';
import { useTranslation } from 'react-i18next';
import Lines from './images/lines.svg';

export function EnergySave() {

  const { t } = useTranslation();

  const [scroll, setScroll] = useState(0);
  const [deg, setDeg] = useState(15);

  const { isInfiniteDesktopView, isMiddleDesktopView, isTabletView, isMobileView } = useWindowSize();

  window.addEventListener('scroll', () => {
    if (window.scrollY > (scroll + 100)) {
      setScroll(window.scrollY);
      setDeg(deg >= 360 ? 15 : (deg + 15));
    }
  });

  return (
    <ReliabilityStyled>
      <ContainerStyled lgPt="80px" lgPb="80px" mdPt="60px" mdPb="60px" pt="60px" pb="60px">
        <GradientLayerStyled />
        <LineStyled>
          <ImageStyled src={Lines} isFullHeight={false} />
        </LineStyled>
        {isMobileView
        ? <Row rowGap='100px'>
          <ColValueStyled sm={4}>
            <ValueTextStyled>
              {t('energysave-section-text1')}
            </ValueTextStyled>
            <ValueStyled deg={deg}>
              50%
            </ValueStyled>
            <ValueTextStyled>
              {t('energysave-section-text2')}
            </ValueTextStyled>
            <RegularTextStyled color='#BFBFBF'>
              {t('energysave-section-text3')}
            </RegularTextStyled>
          </ColValueStyled>
          <ColValueStyled lg={4} md={3} isRight>
            <ValueTextStyled>
              {t('energysave-section-text4')}
            </ValueTextStyled>
            <ValueStyled deg={deg}>
              15%
            </ValueStyled>
            <ValueTextStyled>
              {t('energysave-section-text5')}
            </ValueTextStyled>
            <RegularTextStyled color='#BFBFBF'>
              {t('energysave-section-text6')}
            </RegularTextStyled>
          </ColValueStyled>
        </Row>
        : <Row style={{ paddingTop: '50px', marginBottom: '120px', zIndex: '2', position: 'relative' }}>
          {(isInfiniteDesktopView || isMiddleDesktopView) && <Col lg={1} md={1} />}
          <ColValueStyled lg={4} md={3}>
            <ValueTextStyled>
              {t('energysave-section-text1')}
            </ValueTextStyled>
            <ValueStyled deg={deg}>
              50%
            </ValueStyled>
            <ValueTextStyled>
              {t('energysave-section-text2')}
            </ValueTextStyled>
            <RegularTextStyled color='#BFBFBF'>
              {t('energysave-section-text3')}
            </RegularTextStyled>
          </ColValueStyled>
          {(isInfiniteDesktopView || isMiddleDesktopView || isTabletView) && <Col lg={2} md={2} />}
          <ColValueStyled lg={4} md={3} isRight>
            <ValueTextStyled>
              {t('energysave-section-text4')}
            </ValueTextStyled>
            <ValueStyled deg={deg}>
              15%
            </ValueStyled>
            <ValueTextStyled>
              {t('energysave-section-text5')}
            </ValueTextStyled>
            <RegularTextStyled color='#BFBFBF'>
              {t('energysave-section-text6')}
            </RegularTextStyled>
          </ColValueStyled>
          {(isInfiniteDesktopView || isMiddleDesktopView) && <Col lg={1} md={1} />}
        </Row>}
      </ContainerStyled>
    </ReliabilityStyled>
  );
}
