import { Col, Container, Row } from '@astrn/common/components/grid';
import { LinkStyled } from '@astrn/common/components/link-styled';
import {
  HeadingLevel3,
  RegularTextLevel1,
} from '@astrn/common/tokens/typography';
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import FAQData from '@astrn/data/faq.json';
import { useTranslation } from 'react-i18next';
import filter from 'lodash/filter';
import includes from 'lodash/includes';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

import "./styles.css";

export function FAQCarousel() {
  const { t, i18n } = useTranslation();


  return (
    <Container lgPt="80px" lgPb="80px" pb="80px">
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        style={{
          padding: "0 49px",
          "--swiper-navigation-color": "#ffb22e",
          "--swiper-pagination-color": "#ffb22e",
        }}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSlideChange={() => null}
        onSwiper={(swiper: any) => null}
      >
        {/* @ts-ignore */}
        {filter(FAQData[i18n.language], (question) => includes([1, 6, 8], question.id)).map((item: any, index: number) => (
          <SwiperSlide key={index}>
            <Row>
              <Col lg={4}>
                <HeadingLevel3>{item.question}</HeadingLevel3>
              </Col>
              <Col lg={8}>
                <RegularTextLevel1 dangerouslySetInnerHTML={{ __html: item.previewAnswer || item.answer }} />
              </Col>
            </Row>
          </SwiperSlide>
        ))}
      </Swiper>
      <Row>
        <Col style={{ textAlign: "center", padding: "32px 0" }}>
          <LinkStyled to="/faq">{t('faq-home-button')}</LinkStyled>
        </Col>
      </Row>
    </Container>
  );
}
