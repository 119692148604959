import styled from 'styled-components';
import { getTextStyles } from '../../common/utils/styles';
import { Col } from '../../common/components/grid';
import { ADAPTIVE } from '../../common/tokens/screen';
import { HeadingLevel2, RegularTextLevel1 } from '../../common/tokens/typography';

export const TitleStyled = styled(HeadingLevel2)`
  text-transform: uppercase;
  margin-bottom: 26px;
`;

export const DescriptionStyled = styled(RegularTextLevel1)`
  color: #F0F0F0;
`;

export const FormStyled = styled.form`
  padding: 16px;

  ${ADAPTIVE.minWidth.tablet} {
    padding: 24px;
  }

  ${ADAPTIVE.minWidth.desktop} {
    padding: 40px;
  }
  background-color: #1F1F1F;
  color: #F0F0F0;
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(85, 105, 135, 0.10);
`;

export const FormRowStyled = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  ${getTextStyles('Montserrat', 400, 20, 30)}

  label {
    margin-bottom: 10px;
  }

  input {
    ${getTextStyles('Montserrat', 400, 20, 30)}
    padding: 10px 14px;
    height: 22px;
    display: flex;

    border-radius: 8px;
    border: 1px solid #000;
    background: #141414;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    color: #FFFFFF;

    &::placeholder {
      color: #BFBFBF;
      display: flex;
      align-items: center;
    }

    &:focus {
      border-color: #f0b642;
      box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
      outline: 0;
      background-color: #000;
    }
  }

  textarea {
    ${getTextStyles('Montserrat', 400, 20, 30)}
    height: 220px;
    padding: 10px 14px;
    color: #FFFFFF;

    border-radius: 8px;
    border: 1px solid #000;
    background: var(--Palette-Gray-Grey-900, #141414);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

    &::placeholder {
      color: #BFBFBF;
      ${getTextStyles('Montserrat', 400, 20, 30)}
    }

    &:focus {
      border-color: #f0b642;
      box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
      outline: 0;
      background-color: #000;
    }
  }
`;

export const ColStyled = styled(Col)`
  gap: 16px;
  display: flex;
  flex-direction: column;

  ${ADAPTIVE.minWidth.tablet} {
    flex-direction: row;
  }

  ${ADAPTIVE.minWidth.desktop} {
    flex-direction: column;
  }
`;

export const SocialStyled = styled.div`
  display: inline-flex;
`;

export const SocialTextStyled = styled.div`
  display: inline-flex;
  flex-direction: column;
`;