import styled, { css } from 'styled-components';
import { Col, Container } from '@astrn/common/components/grid';
import { getTextStyles } from '@astrn/common/utils/styles';
import { textLayerShadow } from '@astrn/common/tokens/elevations';
import { RegularTextLevel1 } from '@astrn/common/tokens/typography';
import { ADAPTIVE } from '@astrn/common/tokens/screen';
import { Image } from '@astrn/common/components/image';

export const ReliabilityStyled = styled.div`
  ${ADAPTIVE.maxWidth.mobile} {
    /* height: 500px; */
  }

  ${ADAPTIVE.minWidth.tablet} {
    /* min-height: 612px; */
  }

  ${ADAPTIVE.minWidth.tablet} {
    /* height: 724px; */
  }
`;

export const ContainerStyled = styled(Container)`

  ${ADAPTIVE.minWidth.mobile} {
    /* min-height: 450px; */
  }

  ${ADAPTIVE.minWidth.desktop} {
    /* min-height: 618px; */
  }
`;

export const GradientLayerStyled = styled.div`

  ${ADAPTIVE.minWidth.mobile} {
    background-size: 100% 311px;
    background-repeat: no-repeat;
    background-position-y: 150px;
    height: 400px;
  }

  ${ADAPTIVE.minWidth.tablet} {
    background-size: 100% 218px;
    background-position-y: 30px;
    background-position-x: 50px;
    height: 600px;
  }

  ${ADAPTIVE.minWidth.desktop} {
    background-size: 100% 280px;
    background-position-y: auto;
    background-position-x: 50px;
  }

  ${ADAPTIVE.minWidth.desktopMd} {
    background-size: 100% 300px;
    background-position-y: auto;
    background-position-x: 50px;
  }

  ${ADAPTIVE.minWidth.desktopLg} {
    background-size: 100% 324px;
    background-position-y: 155px;
    background-position-x: 50px;
  }

  z-index: 0;
  width: 100%;
  position: absolute;
  background: radial-gradient(29.32% 39.65% at 50% 50%, #1D4079 0.02%, rgba(1, 1, 1, 0.00) 100%);
`;

export const LineStyled = styled.div`

  position: absolute;
  right: 0;
  z-index: 1;
  height: 100%;
  display: flex;

  ${ADAPTIVE.minWidth.mobile} {
    top: 0;
  }

  ${ADAPTIVE.minWidth.tablet} {
    width: 100%;
    img {
      width: 50%;
    }
    top: auto;
  }

  ${ADAPTIVE.minWidth.desktop} {
    width: 100%;
    img {
      width: 50%;
    }
  }

  ${ADAPTIVE.minWidth.desktopMd} {
    width: 100%;
    img {
      width: 50%;
    }
  }

  ${ADAPTIVE.minWidth.desktopLg} {
    width: 100%;
    img {
      width: 50%;
    }
  }
`;

export const ImageStyled = styled(Image)`
  ${ADAPTIVE.minWidth.mobile} {
    vertical-align: middle;
    display: flex;
  }

  ${ADAPTIVE.minWidth.tablet} {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
  }

  ${ADAPTIVE.minWidth.desktop} {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
  }

  ${ADAPTIVE.minWidth.desktopMd} {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
  }

  ${ADAPTIVE.minWidth.desktopLg} {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
  }
`;

export const HeadingStyled = styled.div`
  
  color: #FFB22E;
  text-shadow: ${textLayerShadow};

  ${ADAPTIVE.minWidth.mobile} {
    ${getTextStyles('Montserrat', 700, 20, 30)}
    margin-bottom: 14px;
  }

  ${ADAPTIVE.minWidth.desktop} {
    ${getTextStyles('Montserrat', 700, 24, 32)}
  }
`;

export const RegularTextStyled = styled(RegularTextLevel1)`
  text-shadow: ${textLayerShadow};
`;

export const ColStyled = styled(Col)`
  display: flex;
  flex-direction: column;
  
  ${ADAPTIVE.minWidth.mobile} {
    text-align: right;
  }

  ${ADAPTIVE.minWidth.tablet} {
    justify-content: end;
  }

  ${ADAPTIVE.minWidth.desktop} {
    justify-content: center;
    gap: 16px;
    z-index: 5;
    padding-top: 180px;
  }
`;

export const ColValueStyled = styled(Col)`
  display: flex;
  flex-direction: column;
  text-align: left;

  ${ADAPTIVE.minWidth.tablet} {
    text-align: left;
    justify-content: start;
  }

  ${ADAPTIVE.minWidth.desktop} {
    text-align: left;
    justify-content: end;
  }
`;

export const ValueStyled = styled.div<{ deg?: number }>`
  align-self: stretch;
  ${({ deg }) => deg && css`
    background: linear-gradient(${deg}deg, #ffb22e 0%, #2ed9ff 0%, #ffb22e 59%, #2ed9ff 100%);
  `}
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;

  ${ADAPTIVE.minWidth.mobile} {
    ${getTextStyles('Montserrat', 400, 72, 72)}
  }

  ${ADAPTIVE.minWidth.desktop} {
    ${getTextStyles('Montserrat', 500, 128, 128)}
  }
`;

export const ValueTextStyled = styled.div`
  ${getTextStyles('Montserrat', 400, 36, 44)}
  color: #FFB22E;
`;
