import { Connected } from '@astrn/components/Connected';
import Accordion from '@astrn/common/components/accordion';
import { Col, Container, Row } from '@astrn/common/components/grid';
import { FAQSubtitleStyled, FAQTitleStyled } from './styled';
import { useTranslation } from 'react-i18next';
import FAQData from '@astrn/data/faq.json';

export function FAQ() {

  const { t, i18n } = useTranslation();

  return (
    <Container>
      <Row lgMb="80px" mdMb="40px" mb="40px">
        <Col>
          <FAQTitleStyled>
            {t('question-section-title')}
          </FAQTitleStyled>
          <FAQSubtitleStyled>
            {t('question-section-subtitle')}
          </FAQSubtitleStyled>
        </Col>
      </Row>
      <Row >
        <Col lg={2} />
        <Col lg={8}>
          {/* @ts-ignore */}
          {FAQData[i18n.language].map((item: any, index: number) => (
            <Accordion
              key={index}
              theme="faq"
              title={item.question}
              content={item.answer}
            />
          ))}
        </Col>
        <Col lg={2} />
      </Row>
      <Connected />
    </Container>
  );
};
