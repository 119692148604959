import React from 'react';
import { Col, Container, Row } from '../../common/components/grid';
import { RegularTextLevel1 } from '../../common/tokens/typography';
import { AdministratorPanelStyled, LeftColStyled, RightColStyled, RightShadowStyled, ImageStyled } from './styled';
import Tablet from '../../assets/admin-panel-section/tablet.png';
import { ButtonStyled } from '../../common/components/button-styled';
import { Link } from 'react-router-dom';
import { useWindowSize } from '../../common/hooks';
import { useTranslation } from 'react-i18next';

export function AdministratorPanel() {

  const { t } = useTranslation();
  const { isMobileView, isMiddleDesktopView, isInfiniteDesktopView } = useWindowSize();

  return (
    <AdministratorPanelStyled>
      <Container>
        <Row mb='30px' mt='30px'>
          {(isInfiniteDesktopView || isMiddleDesktopView) && <Col lg={1} order={1}/>}
          <LeftColStyled lg={4} md={4} sm={4} order={isMobileView ? 4 : 2}>
            <ButtonStyled type='large' btnWidth='auto'>
              <Link to="https://demo.breakhash.com/#/main" target='blank'>
                {t('admin-panel-link-title')}
              </Link>
            </ButtonStyled>
            <RegularTextLevel1 color='#fff' dangerouslySetInnerHTML={{ __html: t('admin-panel-description') }} />
          </LeftColStyled>
          {(isInfiniteDesktopView || isMiddleDesktopView) && <Col lg={2} order={3} />}
          <RightColStyled lg={4} md={4} sm={4} order={isMobileView ? 2 : 4}>
            <ImageStyled src={Tablet} />
          </RightColStyled>
          {(isInfiniteDesktopView || isMiddleDesktopView) && <Col lg={1} order={isMobileView ? 0 : 5} />}
        </Row>
      </Container>
      <RightShadowStyled />
    </AdministratorPanelStyled>
  );
}
