import { SVGIcon } from '@astrn/common/components/svg-icon';
import ArrowRight from '@astrn/assets/icons/breadcrumbs-arrow.svg';
import { BreadcrumbsStyled } from './styled';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function Breadcrumbs({ title }: { title: string }) {

  const { t } = useTranslation();

  return (
    <BreadcrumbsStyled>
      <Link to='/'>{t('menu-item-home')}</Link><SVGIcon type={ArrowRight} sizes={{ w: 16, h: 16 }} />
      <Link to='/resources'>{t('menu-item-resources')}</Link><SVGIcon type={ArrowRight} sizes={{ w: 16, h: 16 }} />
      {title}
    </BreadcrumbsStyled>
  );
}
